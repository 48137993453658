import React, { useState, useEffect } from 'react';
import '../styles/Dash.css';
import './Userdash.css';
import '../styles/CreateTracking.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FaUserAlt, FaClipboardList, FaCalculator, FaShippingFast, FaBoxOpen, FaMapMarkerAlt, FaTruckLoading, FaGift } from 'react-icons/fa';

import FirstComponent from './Profiledash';
import SecondComponent from './Orders';
import ThirdComponent from  './Address';
import FourthComponent from  '../pages/Trackinginput';
import FifthComponent from './Calculator';
import SixthComponent from './Shipment';
import SeventhComponent from './Pending';

const tabs = [
  { id: 2, icon: <FaUserAlt />, name: 'Profile', component: FirstComponent },
  { id: 3, icon: <FaBoxOpen />, name: 'Orders', component: SecondComponent },
  { id: 4, icon: <FaMapMarkerAlt />, name: 'Address', component: ThirdComponent },
  { id: 5, icon: <FaClipboardList />, name: 'Tracking', component: FourthComponent },
  { id: 6, icon: <FaCalculator />, name: 'Calculator', component: FifthComponent },
  { id: 7, icon: <FaShippingFast />, name: 'Shipment', component: SixthComponent },
  { id: 8, icon: <FaGift />, name: 'Promotions', component: SeventhComponent },
];

const UserDashboard = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarVisible(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch user data when the component mounts
    const token = localStorage.getItem('token');
  
    if (token) {
      axios
        .get('https://p2pbackendserver-six.vercel.app/api/user/profile', {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        })
        .then((response) => { 
          const userData = response.data;
          setUserData(userData);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, []); // Empty dependency array ensures this effect runs only on component mount

  const handleTabClick = (id) => {
    if (id === activeTab) return;
  
    setActiveTab(id);
    setIsLoading(true);
  
    if (window.innerWidth <= 768) {
      setIsSidebarVisible(false);
    }
  };
  

  useEffect(() => {
    setIsLoading(false);
  }, [activeTab]);

  const ActiveComponent = tabs.find(tab => tab.id === activeTab).component;

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };


  const checkLoggedIn = () => {
    const token = localStorage.getItem('token');
    return token !== null;
  };

  const isLoggedIn = checkLoggedIn();

  const handleLogout = async () => {
    // Logout logic here
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('_id');
    toast.success('Logout successful');
    window.location.href = "/";

  };


  return (
    <div className="dash-dashboard">
      <div className='innerdashdash'>
        <div className={`dash-sidebar user-sidebar ${isSidebarVisible ? '' : 'hidden'}`} style={{backgroundColor: '#3C535F'}}>
          <div className="dash-tabs" id='user-dash-tabs'>
            <div className='profile-avatar'>
              <h1>{userData.firstname?.[0]?.toUpperCase()}{userData.lastname?.[0]?.toUpperCase()}</h1>
            </div>
            <div className='inner-tab'>
            {tabs.map(tab => (
              <div
                key={tab.id}
                className={`dash-tab ${activeTab === tab.id ? 'active' : ''}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.icon}
                {tab.name}
              </div>
            ))}
            {!isLoggedIn ? (
                      <div className='link-icon'id='authlinkone'>
                      <Link className='side-nav-lnks' to="/login">Login</Link>
                      </div>
                  ) : (
                    <p style={{color: "red", cursor: "pointer"}} onClick={handleLogout}>Logout</p>
              )}
            </div>
          </div>
        </div>

        <div
          className={`dash-hamburger ${isSidebarVisible ? 'open' : ''}`}
          onClick={toggleSidebar}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className={`dash-content ${isSidebarVisible ? '' : 'dash-sidebar-hidden'}`}>
          {isLoading ? (
            <div className='intro-box'>
              <div className="three-body">
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
              </div>
            </div>
          ) : (
            <ActiveComponent />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
