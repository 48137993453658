import React, { useState, useEffect } from 'react';
import '../styles/Quote.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

function Quote() {
  const [originCountry, setOriginCountry] = useState('');
  const [destinationCountry, setDestinationCountry] = useState('');
  const [shipmentWeightKg, setShipmentWeightKg] = useState('');
  const [shipmentWeightLbs, setShipmentWeightLbs] = useState('');
  const [totalCost, setTotalCost] = useState(0); // Default total cost to 0

  useEffect(() => {
    if (shipmentWeightKg === '') {
      setShipmentWeightLbs('');
      setTotalCost(0);
      return;
    }

    const weightKg = parseFloat(shipmentWeightKg);
    if (isNaN(weightKg) || weightKg <= 0) {
      setShipmentWeightLbs('');
      setTotalCost(0);
      return;
    }

    const lbsEquivalent = weightKg * 2.20462; // 1 kilogram = 2.20462 pounds
    setShipmentWeightLbs(lbsEquivalent.toFixed(2)); // Round to 2 decimal places

    // Check for weight threshold (e.g., 20 kg)
    if (weightKg > 20) {
      toast.error('Weight exceeds the maximum allowed limit.');
      setTotalCost(0);
      return;
    }

    // Calculate shipping rate based on weight range
    let shippingRate = 0;
    if (weightKg <= 2) {
      shippingRate = 30;
    } else if (weightKg <= 4) {
      shippingRate = 55;
    } else if (weightKg <= 6) {
      shippingRate = 75;
    } else if (weightKg <= 8) {
      shippingRate = 90;
    } else if (weightKg <= 11) {
      shippingRate = 110;
    } else if (weightKg <= 14) {
      shippingRate = 130;
    } else if (weightKg <= 16) {
      shippingRate = 150;
    } else if (weightKg <= 18) {
      shippingRate = 170;
    } else {
      shippingRate = 200;
    }

    // Calculate total cost
    const total = shippingRate;
    setTotalCost(total); // Total cost is just the shipping rate
  }, [shipmentWeightKg]); // Update when shipmentWeightKg changes

  return (
    <div className="quotation-container">
      <section className='quotation-text'>
      </section>
      <div className='quotation-overlay'>
        <div className='quotation-left-text'>
          <h1>
            Calculate your shipping in the split of a second
          </h1>
          <p>Easily determine the cost of shipping your packages by inputting your country, shipment weight, and delivery options and receive an instant, accurate estimate of your shipping cost.</p>
          <div className='query-quote'>
            <p>Need more information? </p>
            <Link className='link-to-pricing-page' to="/quote"> Go to pricing</Link>
          </div>
        </div>
      </div>
      <section className="quotation-calculator">
        <div className="quotation-input-group">
          <h2 style={{color: "white"}}>Shipping Price Calculator </h2>
          <div className="quotation-input-group-top">
            <select
              value={originCountry}
              onChange={(e) => setOriginCountry(e.target.value)}
              className='location-select'
            >
              <option className='quoto' value="">Select Origin</option>
              {/* <option value="Ghana">Ghana</option> */}
              <option value="UK">UK</option>
              {/* <option value="US" disabled>US</option> */}
            </select>
            <select
              value={destinationCountry}
              onChange={(e) => setDestinationCountry(e.target.value)}
              className='location-select'
            >
              <option className='quoto' value="">Select Destination</option>
              <option value="Ghana">Ghana</option>
              {/* <option value="UK">UK</option>
              <option value="US" disabled>US</option> */}
            </select>
          </div>

          <div className="quotation-input-group-middle">
            <input
              type="number"
              placeholder="Weight (kg)"
              value={shipmentWeightKg}
              onChange={(e) => setShipmentWeightKg(e.target.value)}
            />
            <input
              type="number"
              placeholder="Weight (lbs)"
              value={shipmentWeightLbs}
              disabled
              className='lbs'
            />
          </div>

          <div className="quote-input-group-bottom">
            <label style={{color: 'white'}}>Total</label>
            <input
              type="number"
              placeholder="Total Cost"
              value={totalCost}
              disabled
           
            />
          </div>
          {window.innerWidth <= 767 ? (
            <div className='query-quote'>
              <p style={{color: 'white'}}>Need more information? </p>
              <Link style={{fontWeight: '200'}} className='link-to-pricing-page' to="/quote"> Go to pricing</Link>
            </div>
          ) : (
            null
          )}
        </div>
      </section>
    </div>
  );
}

export default Quote;
