import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Navbar from '../components/Navbar';
// import Profile from '../components/Profile';
import CopyAddress from '../components/CopyAddress';
import { Fade } from 'react-awesome-reveal'; // Updated import
import Quote from '../components/Quote';
import SecondIntro from '../components/SecondIntro';
import CreateAccount from '../components/CreateAccount';
import Slider from '../components/Slider';
import ScrollIndicator from '../components/ScrollIndicator';
import FaqComp from '../components/FaqComp';
import HomeBoxes from '../components/HomeBoxes';
import AnimatedText from '../components/AnimatedTexts';
import ProcessMain from '../components/ProcessMain';
import ReadmoreInfo from '../components/ReadmoreInfo';
import WhatsappChat from '../components/WhatsappChat';
import UserChat from '../SupportChat/SelfChat/UserChat';
const Reviews = React.lazy(() => import('../components/Reviews'));

const Homepage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 915);
    const [index, setIndex] = useState(0);
    // const [showOverlayMenu, setShowOverlayMenu] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 915);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % h1slide.length);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const trackingNumber = e.target.elements.trackingNumber.value.trim();

        if (!trackingNumber) {
            toast.error('Please enter a tracking number');
            return;
        }
    
        try {
            const response = await axios.post('https://p2pbackendserver-six.vercel.app/check-tracking', { trackingNumber });
            navigate(`/tracking/${trackingNumber}`, { state: { trackingInfo: response.data } });
        } catch (error) {
            console.error('Tracking failed:', error);
            if (error.response && error.response.status === 404) {
                toast.error('Tracking number not found');
            } else if (error.response) {
                toast.error('An unexpected error occurred');
            } else if (error.request) {
                toast.error('No response received from server');
            } else {
                toast.error('Error setting up the request');
            }
        }
    };
    
    const h1slide = ["Shop from Anyhere, Ship with Us", "Effortless UK-Ghana Trade"];
    const pslide = ["Easily shop from any online store worldwide and have your purchases delivered to our secure UK warehouse. From there, we take care of everything, shipping your goods safely and reliably to Ghana.", "Our competitive rates and reliable delivery options make it easier than ever to shop from the UK and receive your goods in Africa"];

    const checkLoggedIn = () => {
        const token = localStorage.getItem('token');
        return token !== null;
    };


    const isLoggedIn = checkLoggedIn();

    const handleShipWithUsClick = useCallback(() => {
        // setShowOverlayMenu(false);
        if (isLoggedIn) {
          navigate('/process');
        } else {
          navigate('/login');
        }
      }, [isLoggedIn, navigate]);

    return (
        <div className="homepage-container">

            <div className='home-section'>
              <div className='homesection-overlay'></div>
                <WhatsappChat />
                {/* <Profile /> */}
                <CopyAddress />  
                <div className='centered-items'>
                    <div className='centered-texts'>
                        <Fade direction='left' duration={2000}>
                            <h1>{h1slide[index]}</h1>
                        </Fade>
                        <Fade direction='bottom' duration={2000}>
                        <p>{pslide[index]}</p>
                        </Fade>
                    <HomeBoxes />
                    <CreateAccount /><br /><br />

                    <div className='input-search-items'>
                        <form onSubmit={handleSubmit}>
                            <input type='text' name="trackingNumber" className='center-input' placeholder='Track your order' />
                            <button type='submit' className='center-button'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                                    <path d="M19.5 17.5C19.5 18.8807 18.3807 20 17 20C15.6193 20 14.5 18.8807 14.5 17.5C14.5 16.1193 15.6193 15 17 15C18.3807 15 19.5 16.1193 19.5 17.5Z" stroke="currentColor" strokeWidth="1.5" />
                                    <path d="M9.5 17.5C9.5 18.8807 8.38071 20 7 20C5.61929 20 4.5 18.8807 4.5 17.5C4.5 16.1193 5.61929 15 7 15C8.38071 15 9.5 16.1193 9.5 17.5Z" stroke="currentColor" strokeWidth="1.5" />
                                    <path d="M14.5 17.5H9.5M19.5 17.5H20.2632C20.4831 17.5 20.5931 17.5 20.6855 17.4885C21.3669 17.4036 21.9036 16.8669 21.9885 16.1855C22 16.0931 22 15.9831 22 15.7632V13C22 9.41015 19.0899 6.5 15.5 6.5M2 4H12C13.4142 4 14.1213 4 14.5607 4.43934C15 4.87868 15 5.58579 15 7V15.5M2 12.75V15C2 15.9346 2 16.4019 2.20096 16.75C2.33261 16.978 2.52197 17.1674 2.75 17.299C3.09808 17.5 3.56538 17.5 4.5 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 7H8M2 10H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </form>
                    </div>
                    </div>
                   
                </div>
            </div>
            <AnimatedText />
            <UserChat />
            <SecondIntro />
            <ProcessMain />
            <ReadmoreInfo />
            <Quote />
            {/* <FaqComp /> */}
            <Slider />
            <Reviews />
            <div className='call-to-action-section'>
                <Fade direction='top' duration={2000}>
                    <h1>Ready to get started? Create an account and start shipping</h1>
                    <button onClick={handleShipWithUsClick} className='intro-button'>Start shipping with us</button>
                </Fade>
            </div>
            <ScrollIndicator />
            <Navbar />

        </div>
    );
};

export default Homepage;

